import * as React from "react"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Link from "../components/Link"
import A from "../components/A"
import Box from "../components/Box"
import Text from "../components/Text"
import SEO from "../components/SEO"
import Flex from "../components/Flex"
import Tag from "../components/Tag"
import { GatsbyImage } from "gatsby-plugin-image"
import { H1, H2, H3, H4 } from "../components/Heading"
import CodeBlock from "../components/CodeBlock"
import { styled } from "../stitches.config"
import Grid from "../components/Grid"
import AnchorTag from "../components/AnchorTag"
import BlockQuote from "../components/BlockQuote"
import { UnorderedList } from "../components/List"

const shortcodes = {
    a: A,
    h1: H1,
    h2: H2,
    h3: H3,
    h4: H4,
    pre: CodeBlock,
    blockquote: BlockQuote,
    ul: UnorderedList,
    Link,
}

interface Reference {
    id: string
    excerpt: string
    frontmatter: {
        title: string
    }
    slug: string
}

export interface PostTemplateProps {
    data: {
        mdx: {
            body: any
            excerpt: string
            timeToRead: number
            inboundReferences: Reference[]
            outboundReferences: Reference[]
            frontmatter: {
                title: string
                author: string
                tags: string[]
                type: string
                featuredImage?: {
                    childImageSharp: {
                        gatsbyImageData: any
                    }
                }
            }
        }
    }
}

const ReferenceContainer = styled(Flex, {
    background: "$mauve4",
    borderRadius: "$2",
    p: "$4",
})

export default function PostTemplate({
    data: {
        mdx: {
            body,
            excerpt,
            frontmatter,
            timeToRead,
            inboundReferences,
            outboundReferences,
        },
    },
}: PostTemplateProps) {
    const featuredImgFluid =
        frontmatter.featuredImage?.childImageSharp.gatsbyImageData

    const publicInboundReferences = inboundReferences.filter(
        (r) => r.frontmatter.private === false
    )

    return (
        <Box>
            <SEO title={frontmatter.title} description={excerpt} />
            {featuredImgFluid && (
                <Box css={{ mb: "$4" }}>
                    <GatsbyImage
                        alt="featured image"
                        image={featuredImgFluid}
                    />
                </Box>
            )}
            <Text size="heading-07" css={{ mb: "$4" }}>
                {frontmatter.title}
            </Text>
            <Flex
                direction="column"
                gap="2"
                css={{ color: "$mauve11", mb: "$4" }}
            >
                {frontmatter.author && (
                    <Text size="label-02">Author: {frontmatter.author}</Text>
                )}
                {timeToRead && (
                    <Text size="label-02">Time to Read: {timeToRead}min</Text>
                )}
                {frontmatter.tags && frontmatter.tags.length > 0 && (
                    <Flex align="center" gap="1">
                        <Text size="label-02">Tags: </Text>
                        <Flex gap="1">
                            {frontmatter.tags.map((t) => (
                                <Tag key={t}>{t}</Tag>
                            ))}
                        </Flex>
                    </Flex>
                )}
            </Flex>
            <Text
                size="body-03"
                css={{
                    "h1, h2, h3, h4": {
                        pt: "$4",
                        pb: "$2",
                    },
                    pre: {
                        my: "$2",
                    },
                }}
            >
                <MDXProvider components={shortcodes}>
                    {/* @ts-ignore */}
                    <MDXRenderer frontmatter={frontmatter}>{body}</MDXRenderer>
                </MDXProvider>
            </Text>
            {publicInboundReferences.length > 0 && (
                <Flex direction="column" gap="2">
                    <Text size="heading-01" css={{ color: "$mauve9" }}>
                        Referenced by
                    </Text>
                    <Grid columns="2" gap="4">
                        {publicInboundReferences.map((reference) => (
                            <ReferenceContainer
                                key={reference.id}
                                direction="column"
                            >
                                <H4>
                                    <Link
                                        key={reference.id}
                                        to={`/${reference.slug}`}
                                    >
                                        {reference.frontmatter.title}
                                    </Link>
                                </H4>
                                <Text size="body-01">{reference.excerpt}</Text>
                            </ReferenceContainer>
                        ))}
                    </Grid>
                </Flex>
            )}
        </Box>
    )
}

export const pageQuery = graphql`
    query PostQuery($id: String) {
        mdx(id: { eq: $id }) {
            id
            body
            excerpt(truncate: true, pruneLength: 130)
            timeToRead
            frontmatter {
                title
                author
                tags
                type
                featuredImage {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
            inboundReferences {
                ... on Mdx {
                    id
                    excerpt(truncate: true, pruneLength: 80)
                    frontmatter {
                        title
                        private
                    }
                    slug
                }
            }
            outboundReferences {
                ... on Mdx {
                    id
                    excerpt(truncate: true, pruneLength: 80)
                    frontmatter {
                        title
                        private
                    }
                    slug
                }
            }
        }
    }
`
