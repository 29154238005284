import * as React from "react"
import { styled } from "../stitches.config"

const BlockQuote = styled("blockquote", {
    background: "$mauve3",
    fontSize: "$2",
    p: "$3",
    color: "$mauve11",
    borderLeft: "solid $mauve11 2px",
    my: "$4",
})

export default BlockQuote
