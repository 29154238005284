import * as React from "react"
import { styled } from "../stitches.config"

const Tag = styled("span", {
    fontSize: "$1",
    background: "$realBlue",
    color: "$white",
    display: "inline",
    borderRadius: "$1",
    px: "$1",
    py: "$1",
})

export default Tag
